import React from 'react';

import Home from './components/Home';

function LandingPage() {
  return (
    <>
      <Home />
    </>
  );
}

export default LandingPage;
